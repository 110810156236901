import React from 'react';
import { Route, Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children }) => {
    const isAuthenticated = () => {
        const token = localStorage.getItem('token');
        return !!token; // Convert token presence to a boolean
    };

    return isAuthenticated() ? children : <Navigate to="/login" />;
};
