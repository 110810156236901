
import { Routes, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import reducersBundle from './reducers';

import { App } from './components';

import Header from './components/ui/Header';
import Login from './components/ui/Login';
import Licences from './components/ui/Licences';
import AdminDashboard from './components/ui/AdminDashboard';
import CreateUser from './components/ui/CreateUser';
import { PrivateRoute } from "./PrivateRoute";
import { AdminRoute } from "./AdminRoute";

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducersBundle);

const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store);

const AppRoutes = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <App>
          <Header />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/licences" element={<PrivateRoute><Licences /></PrivateRoute>} />
            <Route path="/dashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
            <Route path="/create-user" element={<AdminRoute><CreateUser /></AdminRoute>} />
          </Routes>
        </App>
      </HashRouter>
    </PersistGate>
  </Provider>
);

export default AppRoutes;

