import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, MenuItem, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// Validation Schema
const UserSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  role: Yup.string().required('Required'),
  company_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string().required('Required'),
});

const CreateUserForm = () => {

  const user = useSelector((state) => state.user);
  const [successMessage, setSuccessMessage] = useState('');

  return (
    <Paper style={{ padding: 20, maxWidth: 500, margin: '20px auto' }}>
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        Create User
      </Typography>
      <Formik
        initialValues={{
          username: '',
          password: '',
          confirmPassword: '',
          role: '',
          company_name: '',
          email: '',
          name: '',
        }}
        validationSchema={UserSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.post(
              `${apiBaseUrl}/create-user`,
              values,
              { headers: { Authorization: `Bearer ${user.token}` } }
            );

            if (response.data?.success) {
              setSuccessMessage('User created successfully');
            }
          } catch (error) {
            console.error('Submission error:', error);
            // Handle error (e.g., show error message)
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, handleChange, isSubmitting }) => (
          <Form>
            <Field
              as={TextField}
              name="username"
              label="Username"
              fullWidth
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              margin="normal"
            />
            <Field
              as={TextField}
              type="password"
              name="password"
              label="Password"
              fullWidth
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              margin="normal"
            />
            <Field
              as={TextField}
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              fullWidth
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              margin="normal"
            />
            <Field
              as={TextField}
              name="role"
              label="Role"
              select
              fullWidth
              error={touched.role && Boolean(errors.role)}
              helperText={touched.role && errors.role}
              margin="normal"
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Field>
            <Field
              as={TextField}
              name="company_name"
              label="Company Name"
              fullWidth
              error={touched.company_name && Boolean(errors.company_name)}
              helperText={touched.company_name && errors.company_name}
              margin="normal"
            />
            <Field
              as={TextField}
              name="email"
              label="Email"
              fullWidth
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              margin="normal"
            />
            <Field
              as={TextField}
              name="name"
              label="First and Last Name"
              fullWidth
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              margin="normal"
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              style={{ marginTop: 20 }}
            >
              Create User
            </Button>
            {successMessage && (
              <Typography color="success.main" style={{ marginTop: 10 }}>
                {successMessage}
              </Typography>
            )}
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default CreateUserForm;
