import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Box, Paper, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import RequestItem from './RequestItem';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const AdminDashboard = () => {
  const [licenceRequests, setLicenceRequests] = useState([]);
  const [availableLicenses, setLicenses] = useState([]);
  
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchLicenceRequests = async () => {
      try {
        const response = await axios.post(
          `${apiBaseUrl}/get-all-licence-requests`,
          user,
          {
            headers: {
              Authorization: `Bearer ${user.token}`
            }
          }
        ); // Update with your API endpoint
        if(response.data?.requests){
          setLicenceRequests(response.data.requests);
          setLicenses(response.data.licenses)
          setIsLoading(false);  
        }
        
      } catch (error) {
        console.error('Failed to fetch licence requests:', error);
        // Handle error (e.g., show error message)
        setIsLoading(true);
      }
    };

    fetchLicenceRequests();
  }, []);

  const handleRequestUpdate = async (updatedRequest) => {
    try {
      
      const response = await axios.post(
        `${apiBaseUrl}/handleLicenceRequest`,
        updatedRequest,
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        }
      ); // Update with your API endpoint
      if(response.data?.requests){
        setLicenceRequests(response.data.requests);
      }
      // Handle success response
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <Paper sx={{minHeight: '50em', backgroundColor:"#ccc", margin:0, padding:0}}>

      <Typography variant="h6" align="center" gutterBottom sx={{paddingTop:"2em"}}>Admin Dashboard</Typography>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
          <CircularProgress />
        </Box>
      ):(
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {licenceRequests.map((request, index) => (
            <RequestItem              
              key={index}
              request={request}
              availableLicenses={availableLicenses}
              onRequestUpdate={handleRequestUpdate}
            />
          ))}
        </Box>
        
      )}
    </Paper>
  );
};

export default AdminDashboard;
