import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { TextField, Button, Grid, Typography, Box, Paper } from '@mui/material';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';


import LicencesOverview from "./LicencesOverview";
import NewLicences from "./NewLicences";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Licences = () => {

  const [licenceRequests, setLicenceRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector((state) => state.user);

  const onLicenceUserUpdate = (data) =>{
    console.log(data)
  }

  const submitRequest = async (data) =>{
    try {
      const response = await axios.post(
        `${apiBaseUrl}/request-license`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        }
      ); // Update with your API endpoint

      if (response.data && response.data.requests) {
        setLicenceRequests(response.data.requests)
        console.log("Success!")
      }

      // Handle success response
    } catch (error) {
      console.error('Submission error:', error);
    }
  }

  useEffect(() => {
    const fetchLicenceRequests = async () => {
      try {
        const response = await axios.post(`${apiBaseUrl}/get-licence-requests`, user); // Update with your API endpoint
        if(response.data){
          setLicenceRequests(response.data.requests);
          setIsLoading(false);  
        }
        
      } catch (error) {
        console.error('Failed to fetch licence requests:', error);
        // Handle error (e.g., show error message)
        setIsLoading(true);
      }
    };

    fetchLicenceRequests();
  }, []);

  return (
    <Grid container spacing={0} justifyContent="center" sx={{backgroundColor: "#ccc", paddingBottom:"2em"}}>

      <Grid item xs={12} md={6}>
        <NewLicences
          submitRequest={submitRequest}
          licenses={[{"name":"Single Engine", value:"Single Engine"}, {name:"Dual Engine", value:"Dual Engine"}]}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <CircularProgress />
          </Box>
        ):(
          <Paper elevation={3} sx={{ p: 3, maxWidth: "100em", mx: 'auto', mt: 4 }}>
            <Typography variant="h6" align="center" gutterBottom>Requests Overview</Typography>
            {licenceRequests.map((request, index) => (
              <LicencesOverview
                key={index}
                licenses={request.licenses}
                productionName={request.production_name}
                licenseNumber={request.licences_count}
                status={request.status}
                onUpdate={onLicenceUserUpdate}
                startDate={request.start_date}
                endDate={request.end_date}
              />
            ))}
          </Paper>
        )}
      </Grid>
    
    </Grid>
  );
};

export default Licences;
