import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../actions';

import { TextField, Button, Box, Paper, Typography, styled } from '@mui/material';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const PREFIX = 'Header';

const classes = {
  root: `${PREFIX}-root`,
  login: `${PREFIX}-login`
};

const Root = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50em',
    backgroundColor: theme.palette.secondary.main
    
  },
  [`& .${classes.login}`]: {
    
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: "25em",
    '& img':{marginTop: "2em"},
    '& .MuiTextField-root': { marginBottom :"2em", width: '20em' },
    '& .MuiButton-root': { marginBottom: "2em"}
    
  }

}));

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const handleLogin = async () => {
  try {
    const res = await axios.post(`${apiBaseUrl}/login`, { username, password });
    localStorage.setItem('token', res.data.token); // Store the token


    // Decode JWT payload
    const base64Url = res.data.token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decodedPayload = atob(base64);
    const payload = JSON.parse(decodedPayload);
    payload.token = res.data.token;
    
    // Dispatch action to Redux store
    dispatch(setUser(payload));

    if(payload.role ==="admin"){
      navigate('/dashboard'); // Redirect to licences page  
    }else{
      navigate('/licences'); // Redirect to licences page
    }
    
  } catch (error) {
    console.error(error);
    // Handle login error
  }
};

  return (
    <Root className={classes.root}
    >
      <Paper className={classes.login}>
        <img
            src="/onair_logo.png"
            alt=""
            style={{ marginLeft:"1.0em", marginBottom: "1em" }}
          />
        <Typography variant="h5" sx={{ mb: 2 }}>
          Login
        </Typography>
        <TextField
          label="Username"
          onChange={e => setUsername(e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Password"
          type="password"
          onChange={e => setPassword(e.target.value)}
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
      </Paper>
    </Root>
  );
};

export default LoginPage;
