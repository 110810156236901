import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from './ui/Header';
// import { DataProvider } from './DataProvider';
// import { createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { updateProducts } from '../actions';
import '@fontsource/roboto';


const theme = createTheme({
	palette: {
        background:{
            main: "#ffffff"
        },
    	primary: {
    		main: '#c22011',
    		light: '#FF4433',
    		dark: '#A30E00'
    	},
    	secondary: {
    		main: '#ccc',
    		light: '#EBFFF6',
    		dark: '#ADFFDA'
    	},
    	warning:{
    		main: '#FFD6DA',
    		light: '#FFEBED',
    		dark: '#FFC2C8'
    	},
    	extras:{
    		main: '#EDABC8',
    		light: '#F8DDE9',
    		dark: '#E689B1'
    	}

  	},
    typography: {
        fontFamily: 'Roboto',
        fontSize: 12,

    },
    components: {
        // Extend with additional styles
        MuiTextField: {
          variants: [
            {
              props: { variant: 'startDate' },
              style: { backgroundColor: 'lightgreen' },
            },
            {
              props: { variant: 'endDate' },
              style: { backgroundColor: 'lightcoral' },
            },
          ],
        },
    }
})

export const App = (props) => {
	const dispatch = useDispatch();
 	const location = useLocation();

	return(
		<ThemeProvider theme={theme}>
			<div className="app">
		        { props.children }
	    	</div>
	    </ThemeProvider>
	)

}
