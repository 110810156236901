import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField, Typography, styled, Box, Grid, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const PREFIX = 'RequestItem';

const classes = {
  status: `${PREFIX}-status`,
  info: `${PREFIX}-info`,
  data: `${PREFIX}-data`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "80em",
  [`&.${classes.status}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.info}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.data}`]: {
    fontWeight: 'bold',
    paddingLeft: "0.2em"
  }
}));

const RequestItem = ({ request, availableLicenses, onRequestUpdate }) => {
  const [rejectionReason, setRejectionReason] = useState('');
  const [licenseAssignments, setLicenseAssignments] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [selectionWarning, setSelectionWarning] = useState(true);

  useEffect(() => {
    // Initialize license assignments based on request.licenses or licences_count
    if (request.licenses && Array.isArray(request.licenses)) {
      setLicenseAssignments(request.licenses.map(license => ({ ...license, value: '' })));
    } else if (request.licences_count) {
      // Fallback to using licences_count if licenses array is not available
      const defaultLicenseType = availableLicenses.length > 0 ? availableLicenses[0].name : 'Unknown';
      setLicenseAssignments(Array(request.licences_count).fill().map((_, index) => ({
        name: defaultLicenseType,        
        value: ''
      })));
    } else {
      // If neither licenses nor licences_count is available, set an empty array
      setLicenseAssignments([]);
    }
  }, [request.licenses, request.licences_count, availableLicenses]);

  const formatDateToLocalTimezone = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    }).format(date);
  };

  const handleConfirm = (action) => {
    setConfirmAction(action);
    setOpenConfirm(true);
  };

  const handleLicenseAssignment = (index, value) => {
    const newAssignments = [...licenseAssignments];
    newAssignments[index].value = value;
    setLicenseAssignments(newAssignments);
    setSelectionWarning(newAssignments.some(assignment => assignment.value === ''));
  };

  const executeAction = async () => {
    setOpenConfirm(false);
    if (confirmAction === 'accept' && licenseAssignments.some(assignment => assignment.value === '')) {
      setSelectionWarning(true);
      return;
    }
    
    const updatedRequest = {
      ...request,
      status: confirmAction === 'accept' ? 'approved' : 'rejected',
      rejectionReason: confirmAction === 'reject' ? rejectionReason : '',
      licenses: licenseAssignments
    };
    onRequestUpdate(updatedRequest);
  };

  const getStatusStyles = () => {
    switch (request.status) {
      case 'approved': return { color: 'green', fontWeight: 'bold', paddingLeft:"0.2em" };
      case 'rejected': return { color: 'red', fontWeight: 'bold', paddingLeft:"0.2em" };
      case 'pending': return { color: 'orange', fontWeight: 'bold', paddingLeft:"0.2em" };
      default: return {};
    }
  };

  return (
    <StyledPaper elevation={2} sx={{ p: 2, mb: 2 }}>
      <Grid container spacing={2}>
        {/* Status, Customer Name, Production Name */}
        <Grid item xs={4} className={classes.info}>
          <Typography variant="subtitle2" color="textSecondary">Status:</Typography>
          <Typography variant="body1" sx={getStatusStyles()}>{request.status}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.info}>
          <Typography variant="subtitle2" color="textSecondary">Customer Name:</Typography>
          <Typography variant="body1" className={classes.data}>{`${request.created_by} | ${request.company_name}`}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.info}>
          <Typography variant="subtitle2" color="textSecondary">Production Name:</Typography>
          <Typography variant="body1" className={classes.data}>{request.production_name}</Typography>
        </Grid>
        {/* Licenses Count, Start Date, End Date */}
        <Grid item xs={4} className={classes.info}>
          <Typography variant="subtitle2" color="textSecondary">Licenses Count:</Typography>
          <Typography variant="body1" className={classes.data}>{request.licences_count}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.info}>
          <Typography variant="subtitle2" color="textSecondary">Start Date:</Typography>
          <Typography variant="body1" className={classes.data}>{formatDateToLocalTimezone(request.start_date)}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.info}>
          <Typography variant="subtitle2" color="textSecondary">End Date:</Typography>
          <Typography variant="body1" className={classes.data}>{formatDateToLocalTimezone(request.end_date)}</Typography>
        </Grid>
        {request.status === "approved" && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Licenses:</Typography>
            {request.licenses && request.licenses.length > 0 ? (
              <Box sx={{ mt: 1 }}>
                {request.licenses.map((license, index) => (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography variant="body2">
                      {`${license.name} - ${license.value || 'Not assigned'}`}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body2">No licenses assigned</Typography>
            )}
          </Grid>
        )}
        {/* License Assignment Section */}
        {request.status === "pending" && (
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle1">Assign Licenses:</Typography>
            {licenseAssignments.map((license, index) => (
              <FormControl key={index} fullWidth margin="dense">
                <InputLabel>{`${license.name} License ${index + 1}`}</InputLabel>
                <Select
                  value={license.value}
                  onChange={(e) => handleLicenseAssignment(index, e.target.value)}
                  label={`${license.name} License ${index + 1}`}
                >
                  {availableLicenses.filter(availableLicense => availableLicense.name === license.name).map((filteredLicense) => (
                    <MenuItem key={filteredLicense.value} value={filteredLicense.value}>
                      {filteredLicense.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
            {selectionWarning && (
              <Typography color="error">Please assign all licenses before accepting.</Typography>
            )}
            <Box sx={{ mt: 2 }}>
              <Button 
                onClick={() => handleConfirm('accept')} 
                color="success" 
                disabled={selectionWarning}
              >
                Accept
              </Button>
              <Button onClick={() => handleConfirm('reject')} color="error">Reject</Button>
            </Box>
            <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
              <Box sx={{ p: 2 }}>
                <Typography>Are you sure you want to {confirmAction} this request?</Typography>
                {confirmAction === 'reject' && (
                  <TextField 
                    label="Rejection Reason" 
                    value={rejectionReason} 
                    onChange={(e) => setRejectionReason(e.target.value)} 
                    fullWidth 
                    margin="normal"
                  />
                )}
                {confirmAction === 'accept' && (
                  <Box>
                    <Typography variant="subtitle1">Assigned Licenses:</Typography>
                    {licenseAssignments.map((license, index) => (
                      <Typography key={index}>
                        {license.name} License {index + 1}: {license.value || 'Not assigned'}
                      </Typography>
                    ))}
                  </Box>
                )}
                <Button onClick={executeAction} color="success">Confirm</Button>
                <Button onClick={() => setOpenConfirm(false)} color="error">Cancel</Button>
              </Box>
            </Dialog>
          </Grid>
        )}
      </Grid>
    </StyledPaper>
  );
};

export default RequestItem;