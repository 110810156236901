import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Paper, Grid, styled } from '@mui/material';

const getStatusColor = (status) => {
  switch (status) {
    case 'approved':
      return 'green';
    case 'declined':
    case 'rejected':
      return 'red';
    case 'pending':
      return 'orange';
    default:
      return 'default';
  }
};

const PREFIX = 'Licences';
const classes = {
  root: `${PREFIX}-root`,
  menuItems: `${PREFIX}-menuItems`,
  discount: `${PREFIX}-discount`,
  cart: `${PREFIX}-cart`
};

const Root = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: "2em",
    backgroundColor: "white"
  },
  [`& .${classes.menuItems}`]: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
  }  
}));

const RequestComponent = ({ productionName, licenseNumber, startDate, endDate, status, onUpdate, licenses }) => {
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({ productionName, licenseNumber, startDate, endDate });

  const formatDateToLocalTimezone = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    }).format(date);
  };

  const handleUpdate = () => {
    setEditMode(false);
    onUpdate({ ...editData, status: 'pending' });
  };

  return (
    <Root elevation={2} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" color="textSecondary">Production Name:</Typography>
          <Typography variant="body1">{productionName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" color="textSecondary">License Number:</Typography>
          <Typography variant="body1">{licenseNumber}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" color="textSecondary">Status:</Typography>
          <Typography variant="body1" sx={{ color: getStatusColor(status) }}>{status}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" color="textSecondary">Start Date:</Typography>
          <Typography variant="body1">{formatDateToLocalTimezone(startDate)}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" color="textSecondary">End Date:</Typography>
          <Typography variant="body1">{formatDateToLocalTimezone(endDate)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">Licenses:</Typography>
          {licenses && licenses.length > 0 ? (
            <Box sx={{ mt: 1 }}>
              {licenses.map((license, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Typography variant="body2">
                    {`${license.name} - ${license.value || 'Not assigned'}`}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography variant="body2">No licenses assigned</Typography>
          )}
        </Grid>
      </Grid>
    </Root>
  );
};

export default RequestComponent;