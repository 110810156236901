import React from 'react';
import { Route, Navigate } from 'react-router-dom';

export const AdminRoute = ({ children }) => {
    const isAuthenticatedAdmin = () => {
        const token = localStorage.getItem('token');
        
        if (!token) return false;
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const decodedPayload = atob(base64);
            const payload = JSON.parse(decodedPayload);
            
            return payload.role === 'admin';
        } catch (error) {
            return false;
        }
    };

    return isAuthenticatedAdmin() ? children : <Navigate to="/login" />;
};
