import React, { useState } from 'react';

import { TextField, Button, Grid, Typography, Paper, Checkbox, FormControlLabel , Box } from '@mui/material';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

const Licences = ({ submitRequest, licenses }) => {
  const [formValues, setFormValues] = useState({
    productionName: '',
    licencesCount: '',
    selectedLicenses: {},
    startDate: new Date(),
    endDate: new Date()
  });

  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const handleDateChange = (prop) => (newValue) => {
    setFormValues({ ...formValues, [prop]: newValue });
  };

  const handleLicenseSelection = (license) => (event) => {
    setFormValues(prevValues => ({
      ...prevValues,
      selectedLicenses: {
        ...prevValues.selectedLicenses,
        [license.value]: event.target.checked ? 0 : undefined
      }
    }));
  };

  const handleLicenseCountChange = (license) => (event) => {
    const count = parseInt(event.target.value, 10);
    setFormValues(prevValues => ({
      ...prevValues,
      selectedLicenses: {
        ...prevValues.selectedLicenses,
        [license.value]: isNaN(count) ? 0 : count
      }
    }));
  };

  const handleSubmit = async () => {
    const selectedLicensesWithCounts = Object.entries(formValues.selectedLicenses)
      .filter(([_, count]) => count !== undefined && count > 0)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    const licencesCount = Object.values(selectedLicensesWithCounts)
      .reduce((total, count) => total + count, 0);

    // Transform selectedLicensesWithCounts into the desired format
    const transformedLicenses = Object.entries(selectedLicensesWithCounts).flatMap(([key, count]) => 
      Array(count).fill().map(() => ({ name: key, value: "" }))
    );

    const submitData = {
      ...formValues,
      licenses: transformedLicenses,
      licencesCount: licencesCount
    };
    submitRequest(submitData);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper elevation={2} sx={{ padding:"2em", maxWidth: 800, mx: 'auto', mt: 4 }}>
        <Typography variant="h6" align="center" gutterBottom>Request New Licence</Typography>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Name of the Production"
              value={formValues.productionName}
              onChange={handleChange('productionName')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: 1 }}>Select Licenses:</Typography>
            <Box sx={{ 
              border: '1px solid #e0e0e0', 
              borderRadius: '4px', 
              p: 2 
            }}>
              {licenses.map((license) => (
                <Box key={license.value} sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 1,
                  '&:last-child': { mb: 0 }
                }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.selectedLicenses[license.value] !== undefined}
                        onChange={handleLicenseSelection(license)}
                      />
                    }
                    label={license.name}
                    sx={{ 
                      mr: 1, 
                      flexGrow: 1,
                      '& .MuiFormControlLabel-label': {
                        fontSize: '0.9rem',
                      }
                    }}
                  />
                  {formValues.selectedLicenses[license.value] !== undefined && (
                    <TextField
                      type="number"
                      value={formValues.selectedLicenses[license.value]}
                      onChange={handleLicenseCountChange(license)}
                      inputProps={{ min: 0, style: { padding: '6px', width: '50px' } }}
                      variant="outlined"
                      size="small"
                      sx={{ width: '70px', ml: 1 }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          </Grid>          
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom sx={{ 
              fontWeight: 'bold', 
              textAlign: 'center', 
              mb: 2  // margin bottom for spacing
            }}>
              Production's Start
            </Typography>
            <StaticDateTimePicker 
              sx={{
                bgcolor: 'lightgreen',
                '& .MuiDialogActions-root': {
                  display: 'none'
                }
              }}
              label="Production's start"
              value={formValues.startDate}
              onChange={handleDateChange('startDate')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom sx={{ 
              fontWeight: 'bold', 
              textAlign: 'center', 
              mb: 2
            }}>
              Production's End
            </Typography>
            <StaticDateTimePicker 
              sx={{ 
                bgcolor: 'lightcoral',
                '& .MuiDialogActions-root': {
                  display: 'none'
                } 
              }}
              label="End Date and Hour"
              value={formValues.endDate}
              onChange={handleDateChange('endDate')}
              renderInput={(props) => <TextField fullWidth {...props} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  );
};

export default Licences;
