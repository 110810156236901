import React from 'react';
import { useSelector } from 'react-redux';
import { AppBar, styled, Toolbar, Typography, IconButton, Badge, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const PREFIX = 'Header';
const classes = {
  root: `${PREFIX}-root`,
  menuItems: `${PREFIX}-menuItems`,
  discount: `${PREFIX}-discount`,
  cart: `${PREFIX}-cart`
};

const Root = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    
    position: 'relative', // Add position relative
    margin: 'auto',
    overflow: 'hidden',    
    backgroundColor: theme.palette.background.main
    
  },
  [`& .${classes.menuItems}`]: {

    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),

  }  
}));

const Header = () => {

  const user = useSelector((state) => state.user);
  
  return (
    <Root position="static" className={classes.root}>
      <Toolbar>
        {/* Logo */}
        <img
          src="/onair_logo.png"
          alt=""
          style={{ width: '60px', height:"25px" }}
        />

        {/* Navigation */}
        <Typography variant="body1" className={classes.menuItems}>
          {user && user.name && user.company_name ? `${user.name} | ${user.company_name}` : ''}
        </Typography>

        
        {/* Spacer */}
        <Box flexGrow={1}></Box>

        {/*
         Shopping Cart
          <IconButton component={Link} to="/checkout" color="inherit" className={classes.cart}>
            <Badge badgeContent={shoppingCart.total_count} color="error">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
         */
        }        
      </Toolbar>
      
    </Root>
  );
};

export default Header;
